.anchor {
  display: inline-block;
  position: relative;
  top: -0.15em;
  left: 0.5em;
  width: 0;
  margin: 0 !important;
  color: var(--primary) !important;
  opacity: 0;
  font-size: 0.75em !important;
  text-decoration: none;
  transition: opacity var(--transition), color var(--transition);
}

:hover > .anchor,
.anchor:focus {
  opacity: 1;
}

.anchor:hover {
  color: var(--text) !important;
}
